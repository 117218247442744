<template>
  <v-treeview
    :items="page.structurizer"
    activatable
    open-on-click
    expand-icon="$chevronRight"
    collapse-icon="$chevronDown"
  >
    <template #title="{ item }">
      <a :href="item.url">{{ item.title }}</a>
    </template>
  </v-treeview>
</template>

<script setup>
const page = inject("page");
</script>
<style scoped>
.v-btn--icon.v-btn--density-compact {
  padding: 0 !important;
}
</style>
